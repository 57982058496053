import React from "react"
import styled from "styled-components"
import { v4 } from 'uuid'
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import { Section, SectionDescription, SectionHeader, SectionPageTitle, MainPanel, MarkdownContent, BreadCrumb } from "../components/section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BreakpointUp from "../components/Media/BreakpointUp"
import generateHTML from '../utils/generateHTML';
import Content, { HTMLContent } from '../components/Content';

const SectionCover = styled.div`  
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  margin:0 -15px;    
  ${BreakpointUp.lg`                  
    padding-left: calc((100% - 1350px)/2);
  `}
`
const CoverFigure = styled.div`
  padding: 0 15px;
  width:100%;
  display:none;
  ${BreakpointUp.lg`   
    display:block;
    flex: 0 0 50%;
    max-width: 50%;
  `}
  .gatsby-image-wrapper {
    max-height: 500px;
    height: 100%;
  }
`
const CoverContent = styled.div`
  position:relative;
  padding:0 15px;
  width:100%;
  ${BreakpointUp.lg`          
    flex: 0 0 50%;
    max-width: 50%;
  `}
  & p{
    margin-bottom:30px;
  }
`

const AboutValue = styled.div`
  width: 100%;		
  margin:0 auto;
}
`
const AboutValueItem = styled.div`
		display:block;		
		margin-bottom: 30px;
		padding: 0 0 0 90px;
		position: relative;
		& .icon{		
			width:74px;
			height:64px;
			background-color:#fff;	
			position: absolute;
			top:0;
			left:0;
			display: flex;
			align-items: center;
      justify-content: center;
      box-shadow: 0 0 10px rgba(0,0,0,0.1);
			svg{
				fill:#666;
			}
		}
		& .text{
			display: flex;
			flex-direction: column;
    		justify-content: center;
			min-height: 64px;
			& h3{
				color:#020304;
				font-weight:700;
				font-size:20px;
				font-family: 'Roboto', serif;
				margin:0;
      }
      & p{
        margin-bottom:0;
        line-heiht:24px;
      }
		}
  }
`

const AboutGrid = styled.div`
  display:flex;
  align-items: center;
  flex-wrap: wrap;
}
`
const MissionVision = styled.div`
  position: relative;
  width: 100%;
  border:1px solid #EAEAEA;
  padding:30px 15px;
  ${BreakpointUp.lg`   
  border-right:none;
  flex: 0 0 41.666667%;
    max-width: 41.666667%;
    padding:50px 45px;
  `}
  & h2{
    margin-bottom:5px;
    font-size:28px;
    line-height:38px;
    ${BreakpointUp.md`                        
      font-size:34px;
      line-height:48px;
    `}
  }
  & p{
    margin-bottom:30px;
  }  
}
`
const ValuePrinciple = styled.div`
  position: relative;
  width: 100%;
  background-color:#F6F6F6;
  border:1px solid #EAEAEA;
  padding:30px 15px;
  ${BreakpointUp.lg`   
    padding:50px 45px;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  `}
  & h2{
    margin-bottom:5px;
    font-size:28px;
    line-height:38px;
    ${BreakpointUp.md`                        
      font-size:34px;
      line-height:48px;
    `}
  }
  & p{
    margin-bottom:30px;
  }
}
`

const AboutUsPage = ({data, location}) => {
  const pageData = data.contentfulAboutUsPage
  const PostContent = HTMLContent || Content;
  return(
    <Layout location={location}>
      <SEO title="About Us"/>                   
      <SectionHeader pt="140px" pb="90px" xpt="140px" xpb="60px" bgColor="linear-gradient(to right,  #f6f6f6 0%,#f6f6f6 75%,#eaeaea 75%,#eaeaea 100%)">
        <div className="container">
          <SectionCover>
            <CoverContent>
              <BreadCrumb><Link to='/'>Home</Link>/<span>About Us</span></BreadCrumb>   
              <SectionPageTitle mt="30px" mb="20px" textAlign="left">
                {pageData.heroTitle}
              </SectionPageTitle>
              <SectionDescription mb="0" textAlign="left">
                <PostContent
                  content={generateHTML(pageData.heroDescription.childMarkdownRemark.html)}
                />
              </SectionDescription>
            </CoverContent>  
            <CoverFigure>
              <Img fluid={pageData.heroImage.fluid} /> 
            </CoverFigure>
          </SectionCover>
        </div>
      </SectionHeader>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px">
        <div className="container">
          <AboutGrid>
            <MissionVision>
              <h2>Our Mission</h2> 
              <p>{pageData.ourMission.ourMission}</p>
              <h2>Our Vision</h2> 
              <p>{pageData.ourVission.ourVission}</p>
            </MissionVision>
            <ValuePrinciple>
              <h2>Our Values And Principles</h2> 
              <p>{pageData.valuesPrinciplesDescription.valuesPrinciplesDescription}</p>
              <AboutValue>
                {
                  pageData.valuesAndPrinciples.map(item=>{
                    return(
                      <AboutValueItem key={v4()}>
                        <div className="icon">
                          <img src={item.icon} alt={item.value} />
                        </div>
                        <div className="text">
                          <h3>{item.value}</h3>
                          <p>{item.description}</p>
                        </div>
                      </AboutValueItem>
                    )
                  })
                }
              </AboutValue>         
            </ValuePrinciple>
          </AboutGrid>

        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#F6F6F6">
        <div className="container">
          <MainPanel>
              <MarkdownContent  pl="15px" pr="15px" maxWidth="1120px">
                <PostContent
                  content={generateHTML(pageData.content.childMarkdownRemark.html)}
                />
              </MarkdownContent>         
          </MainPanel>
        </div>
      </Section>
    </Layout>
  )
} 

export default AboutUsPage

export const pageQuery = graphql`
  query AboutUsPageQuery {
    contentfulAboutUsPage {
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      ourMission {
        ourMission
      }
      ourVission {
        ourVission
      }
      valuesPrinciplesDescription {
        valuesPrinciplesDescription
      }
      valuesAndPrinciples {
        value
        description
        icon
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`